/* eslint-disable */
import $ from 'jquery';
import 'jquery-mask-plugin';
import Swiper from 'swiper';
import 'jquery-parallax.js';
import ymaps from 'ymaps';

window.$ = $;
window.jQuery = $;

// main page slider
$(() => {
  var swiperSectionMain = new Swiper('.section-main .swiper-container', {
    loop: true,
    navigation: {
      nextEl: '.section-main .swiper-button-next',
      prevEl: '.section-main .swiper-button-prev',
    },
    on: {
      slideChange: function () {
        const activSlideElement = $(this.slides[this.activeIndex]);
        const activeBg = $(activSlideElement).data('bg');
        $('.section-main').css({ backgroundImage: `url('${activeBg}')` });
      }
    }
  });
});

// case-project-slider
$(() => {
  const swiper = new Swiper('.case-project-slider .swiper-container', {
    // Optional parameters
    loop: true,
    slidesPerView: 3,
    spaceBetween: 30,
    centeredSlides: true,
    // Navigation arrows
    navigation: {
      nextEl: '.case-project-slider .case-project-slider_btn_next',
      prevEl: '.case-project-slider .case-project-slider_btn_prev',
    },
    breakpoints: {
      320: {
        slidesPerView: 1
      },
      991: {
        slidesPerView: 3
      }
    }
  })
});

//fixed menu
$(function () {
  var box = $('#aside1'); // float-fixed block
  const windowHeight = $(document).height();
  const footerTop = $('.footer').offset().top;
  const footerHeight = $('.footer').height();
  const fixedBlockHeight = $('#aside1').height();

  var we = footerTop - footerHeight - fixedBlockHeight / 2;
  var we1 = footerTop - footerHeight - fixedBlockHeight / 2 + fixedBlockHeight / 1.5;
  var top = box.offset().top;

  $(window).scroll(function () {
    var windowpos = $(window).scrollTop();
    if (windowpos < top) {
      box.removeClass('aside-fixed');
      box.addClass('aside-absolute');
    }
    else if (windowpos > we1) {
      box.css('top', `${we}px`);
      box.removeClass('aside-fixed');
      box.addClass('aside-absolute');
    } else {
      box.addClass('aside-fixed');
      box.removeClass('aside-absolute');
      box.css('top', `15px`);
    }
  });
});

//burger
$(function () {
  var $burgerMenu = $('.burgerbtn')
  $burgerMenu.click(() => {
    $burgerMenu.toggleClass('navbar-burger-active')
  })
});

//filter for fixed menu
$(function () {
  var navigationLink = $('#aside1 .page_wrapper_navigation_item_link');
  navigationLink.click(function (e) {
    e.preventDefault();
    var $el = $(this);
    var id = $el.attr('href'),
      top = $(id).offset().top;
    $('body,html').animate({ scrollTop: top }, 1500);
    if ($el.hasClass('page_wrapper_navigation_item_link_active')) {
      console.log('это сылка активная')
    } else {
      $('a.page_wrapper_navigation_item_link').removeClass('page_wrapper_navigation_item_link_active');
      $el.addClass('page_wrapper_navigation_item_link_active');
    }
  });
});

//mob
$(() => {
  const burger = $('#burger');
  const menu = $('.mob-menu');
  const link = $('.mob-menu__menu a');
  const overlay = $('.overlay');

  burger.on('click', function () {
    if ($(this).hasClass('active')) {
      $(this).removeClass('active').addClass('not-active');
      overlay.removeClass('fadeIn').addClass('fadeOut');
      menu.removeClass('open');
    } else {
      $(this).removeClass('not-active').addClass('active');
      overlay.removeClass('fadeOut').addClass('fadeIn');
      menu.addClass('open');
    }
  });
  overlay.on('click', () => {
    overlay.removeClass('fadeIn').addClass('fadeOut');
    menu.removeClass('open');
    burger.removeClass('active').addClass('not-active');
  });
  link.on('click', () => {
    overlay.removeClass('fadeIn').addClass('fadeOut');
    menu.removeClass('open');
    burger.removeClass('active').addClass('not-active');
  });
});

//search menu
$(function () {
  var $searchBtn = $('.search-button')
  $searchBtn.click(() => {
    var $seachPole = $('.search-button_bg-wrapper input')
    $seachPole.toggleClass('search-button_input')
    $seachPole.toggleClass('flexed_none')
    var $seachSvg = $('.search-button_bg-wrapper svg')
    $seachSvg.toggleClass('search-button_svg')

  })
});

// map
$(() => {
  const map = $('#map_contacts');
  if (map.length) {
    ymaps.load('https://api-maps.yandex.ru/2.1/?lang=ru_RU').then((maps) => {

      const myMap = new maps.Map('map_contacts', {
        center: [57.846720, 37.609905],
        zoom: 6,
        controls: []
      }, {
        searchControlProvider: 'yandex#search',
      });
      const myPlacemark_1 = new maps.Placemark([55.703870, 37.623896], {
        balloonContent: 'Москва, Варшавское шоссе 1а, стр. 6'
      }, {
        iconLayout: 'default#image',
        iconImageHref: '/assets/template/dist/assets/pin.jpg',
        iconImageSize: [34, 34],
        iconImageOffset: [-17, -17]
      });
      // const myPlacemark_2 = new maps.Placemark([59.220975, 39.900659], {
      //     balloonContent: 'Вологда, ул. Зосимовская, д. 5, офис 1'
      // }, {
      //   iconLayout: 'default#image',
      //   iconImageHref: '/assets/template/dist/assets/pin.jpg',
      //   iconImageSize: [34, 34],
      //   iconImageOffset: [-17, -17]
      // });

      myMap.geoObjects.add(myPlacemark_1);
      myMap.geoObjects.add(myPlacemark_2);
      myMap.behaviors.disable('scrollZoom');
      map.addClass('noZoom');
      myMap.events.add('click', () => {
        if (map.hasClass('noZoom')) {
          map.removeClass('noZoom').addClass('yesZoom');
          myMap.behaviors.enable('scrollZoom');
        } else {
          map.removeClass('yesZoom').addClass('noZoom');
          myMap.behaviors.disable('scrollZoom');
        }
      });

      const isMobile = {
        Android: () => (navigator.userAgent.match(/Android/i)),
        BlackBerry: () => (navigator.userAgent.match(/BlackBerry/i)),
        iOS: () => (navigator.userAgent.match(/iPhone|iPad|iPod/i)),
        Opera: () => (navigator.userAgent.match(/Opera Mini/i)),
        Windows: () => (navigator.userAgent.match(/IEMobile/i)),
        any: () => (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows())
      };

      if (isMobile.any()) {
        myMap.behaviors.disable('drag');
      }
    }).catch(error => console.log('Failed to load Yandex Maps', error));
  }
});

// map
$(() => {
  const map = $('#case-map');
  const sectionCaseMap = $('.section.case-map');

  if (map.length) {

    console.log(sectionCaseMap.attr('data-cases'));

    const cases = {
      obsledovanie: [
        {
          title: 'ЖК Светлый мир Я-Романтик',
          desc: ['Корректировка проекта', 'Авторский надзор', 'BIM-проектирование', 'Сопровождение проекта, прохождение экспертизы'],
          date: '2018 год',
          link: '/test_1',
          coords: [59.937763, 30.200369]
        }, {
          title: 'ЖК О’Юность',
          desc: ['Корректировка проекта', 'Авторский надзор', 'BIM-проектирование'],
          date: '2018 год',
          link: '/test_2',
          coords: [59.892181, 30.451859]
        }
      ],
      control: [
        {
          title: 'ЖК Онегин',
          desc: ['Корректировка проекта', 'Авторский надзор'],
          date: '2018 год',
          link: '/test_3',
          coords: [59.205425, 39.905930]
        }
      ],
      iziskania: [
        {
          title: 'ЖК Твои окна',
          desc: ['Корректировка проекта'],
          date: '2018 год',
          link: '/test_4',
          coords: [59.185968, 39.898422]
        }
      ],
    };

    let selected = [];

    const getCheckedFilter = () => {
      selected = [];
      $('.case-map-filter_checkbox').each(function () {
        if ($(this).prop('checked')) {
          selected.push($(this).val());
        }
      });
    }

    const setInfo = (infoObject) => {
      $('.case-map_wrapper_info_title').text(infoObject.title);
      $('.case-map_wrapper_info_date').text(infoObject.date);
      $('.case-map_wrapper_info_button').attr('href', infoObject.link);

      const list = infoObject.desc.map((item) => {
        return $('<li/>', {
          class: 'case-map_wrapper_info_list_item',
          html: item
        });
      });
      $('.case-map_wrapper_info_list').html(list);
    }

    getCheckedFilter();

    ymaps.load('https://api-maps.yandex.ru/2.1/?lang=ru_RU').then((maps) => {

      const myMap = new maps.Map('case-map', {
        center: [59.221238, 39.895134],
        zoom: 4,
        controls: []
      }, {
        searchControlProvider: 'yandex#search',
      });

      const myCollection = new maps.GeoObjectCollection();

      selected.forEach((filterChecked) => {
        cases[filterChecked].forEach((caseItem) => {
          const myPlacemark = new maps.Placemark(caseItem.coords, {}, {
            iconLayout: 'default#image',
            iconImageHref: 'dist/assets/pin.jpg',
            iconImageSize: [34, 34],
            iconImageOffset: [-17, -17]
          });
          myPlacemark.events.add('click', (e) => {
            setInfo(caseItem);
          });

          myCollection.add(myPlacemark);
        });
      });

      $('.case-map-filter_checkbox').on('change', () => {
        myCollection.removeAll();
        getCheckedFilter();
        selected.forEach((filterChecked) => {
          cases[filterChecked].forEach((caseItem) => {
            const myPlacemark = new maps.Placemark(caseItem.coords, {}, {
              iconLayout: 'default#image',
              iconImageHref: 'dist/assets/pin.jpg',
              iconImageSize: [34, 34],
              iconImageOffset: [-17, -17]
            });
            myPlacemark.events.add('click', () => {
              setInfo(caseItem);
            });

            myCollection.add(myPlacemark);
          });
        });
      });

      myMap.geoObjects.add(myCollection);

      myMap.behaviors.disable('scrollZoom');
      map.addClass('noZoom');
      myMap.events.add('click', () => {
        if (map.hasClass('noZoom')) {
          map.removeClass('noZoom').addClass('yesZoom');
          myMap.behaviors.enable('scrollZoom');
        } else {
          map.removeClass('yesZoom').addClass('noZoom');
          myMap.behaviors.disable('scrollZoom');
        }
      });

      const isMobile = {
        Android: () => (navigator.userAgent.match(/Android/i)),
        BlackBerry: () => (navigator.userAgent.match(/BlackBerry/i)),
        iOS: () => (navigator.userAgent.match(/iPhone|iPad|iPod/i)),
        Opera: () => (navigator.userAgent.match(/Opera Mini/i)),
        Windows: () => (navigator.userAgent.match(/IEMobile/i)),
        any: () => (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows())
      };

      if (isMobile.any()) {
        myMap.behaviors.disable('drag');
      }
    }).catch(error => console.log('Failed to load Yandex Maps', error));
  }
});